import IndexNavbar from "components/Navbars/IndexNavbar.js";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import EventList from "../components/events/EventList";
import events from "../components/events/events";
function Events() {
    document.documentElement.classList.remove("nav-open");
    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />

            <Container className="pt-4 mt-5">
                <Row>
                    <Col>
                        <h1 className="title">Wanderungen und Events</h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <EventList events={events} />
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default Events;