import React from "react";
// reactstrap components
import { Container, Row } from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Impressum() {
  return (
    <>
      <IndexNavbar transparentUntilScrolled={false} />
      <div className="main">
        <div className="section text">
          <Container>
            <Row>
              <h1 className="title">Impressum</h1>
            </Row>
            <Row>
              <h4 className="title">Verantwortlich:</h4>
            </Row>
            <Row>
              <h5 className="description">
                Alpakas vom Rosenhof
                <br />
                Stefanie Schwarz und Laura Flessa GbR
                <br />
                Alte Wormser Str. 9 <br />
                68623 Lampertheim <br />
                <br />
                Kontakt:
                <br />
                Tel: +49 1514 4339808
                <br />
                E-Mail: info@alpakas-vom-rosenhof.de <br />
                <br />
                Vertreten durch:
                <br />
                Laura Flessa
                <br />
                Stefanie Schwarz <br />
                <br />
                Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:
                <br />
                005 390 00669
              </h5>
            </Row>
            <Row>
              <h2 className="title">Disclaimer - rechtliche Hinweise</h2>
            </Row>
            <Row>
              <h3 className="title">Auskunfts- und Widerrufsrecht</h3>
              <h5 className="description">
                Sie haben jederzeit das Recht, sich unentgeltlich und
                unverzüglich über die zu Ihrer Person erhobenen Daten zu
                erkundigen. Ebenfalls können Sie Ihre Zustimmung zur Verwendung
                Ihrer angegebenen persönlichen Daten mit Wirkung für die Zukunft
                widerrufen. Hierfür wenden Sie sich bitte an den im Impressum
                angegebenen Diensteanbieter.
              </h5>
              <h3 className="title">Datenschutz (allgemein)</h3>
              <h5 className="description">
                Beim Zugriff auf unsere Webseite werden automatisch allgemeine
                Informationen (sog. Server-Logfiles) erfasst. Diese beinhalten
                u.a. den von Ihnen verwendeten Webbrowser sowie Ihr
                Betriebssystem und Ihren Internet Service Provider. Diese Daten
                lassen keinerlei Rückschlüsse auf Ihre Person zu und werden von
                uns statistisch ausgewertet, um unseren Internetauftritt
                technisch und inhaltlich zu verbessern. Das Erfassen dieser
                Informationen ist notwendig, um den Inhalt der Webseite korrekt
                ausliefern zu können. Die Nutzung der Webseite ist grundsätzlich
                ohne Angabe personenbezogener Daten möglich. Soweit
                personenbezogene Daten (beispielsweise Name, Anschrift oder
                E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben. Sofern
                ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder
                geändert werden soll oder Sie an uns eine Anfrage stellen,
                erheben und verwenden wir personenbezogene Daten von Ihnen,
                soweit dies zu diesem Zwecke erforderlich ist (Bestandsdaten).
                Wir erheben, verarbeiten und nutzen personenbezogene Daten
                soweit dies erforderlich ist, um Ihnen die Inanspruchnahme des
                Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche
                personenbezogenen Daten werden nur solange gespeichert wie dies
                für den genannten Zweck (Bearbeitung Ihrer Anfrage oder
                Abwicklung eines Vertrags) erforderlich ist. Hierbei werden
                steuer- und handelsrechtliche Aufbewahrungsfristen von uns
                berücksichtigt. Auf Anordnung der zuständigen Stellen müssen wir
                im Einzelfall Auskunft über diese Daten (Bestandsdaten)
                erteilen, soweit dies für Zwecke der Strafverfolgung, zur
                Gefahrenabwehr, zur Erfüllung der gesetzlichen Aufgaben der
                Verfassungsschutzbehörden oder des Militärischen
                Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen
                Eigentum erforderlich ist. Wir weisen ausdrücklich darauf hin,
                dass die Datenübertragung im Internet (z. B. bei der
                Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Vor
                dem Zugriff auf Daten kann nicht lückenlos geschützt werden. Die
                Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                angeforderter Werbung und Informationsmaterialien wird hiermit
                ausdrücklich untersagt. Ausgenommen hiervon sind bestehende
                Geschäftsbeziehungen bzw. es liegt Ihnen eine entsprechende
                Einwilligung von uns vor. Die Anbieter und alle auf dieser
                Website genannten Dritten behalten sich ausdrücklich rechtliche
                Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen vor. Gleiches gilt für die kommerzielle
                Verwendung und Weitergabe der Daten.
              </h5>
              <h3 className="title"> Disclaimer (Haftungsausschluss) </h3>{" "}
              <h5 className="description">
                1. Haftung für Inhalte Als Diensteanbieter sind wir gemäß § 7
                Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den
                allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
                wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
                oder gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                werden wir diese Inhalte umgehend entfernen. <br />
                2. Haftung für Links Diese Website enthält Links zu externen
                Webseiten Dritter, auf deren Inhalte kein Einfluss genommen
                werden kann. Deshalb kann für diese fremden Inhalte auch keine
                Gewähr übernommen werden. Für die Inhalte der verlinkten Seiten
                ist stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
                permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
                derartige Links umgehend von dieser Website auf die
                rechtsverletzende Site entfernen. <br />
                3. Urheberrecht Die durch die Diensteanbieter, deren Mitarbeiter
                und beauftragte Dritte erstellten Inhalte und Werke auf diesen
                Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                vorherigen schriftlichen Zustimmung des jeweiligen Autors bzw.
                Erstellers. Downloads und Kopien dieser Seite sind nur für den
                privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
                Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                werden die Urheberrechte Dritter beachtet. Insbesondere werden
                Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
                auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
                um einen entsprechenden Hinweis. Bei Bekanntwerden von
                Rechtsverletzungen werden derartige Inhalte umgehend entfernen.
              </h5>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
export default Impressum;
