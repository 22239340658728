import React from "react";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss?v=1.2.0";

// pages
import Index from "views/Index.js";
import Impressum from "views/Impressum.js";
import Contact from "views/ContactPage";
import Footer from "components/Footers/Footer.js";
import Animals from "views/Animals";
import Anfahrt from "views/Anfahrt"
import Events from "views/Events";
import Yoga from "views/Yoga";
import Meet from "views/Meet";

import Datenschutz from "views/Datenschutz";
import Wanderung from "views/Wanderung";
import Geburtstag from "views/Geburtstag";
import Agb from "views/Agb"
import Gutschein from "views/Gutschein";
import Gluhwein from "views/Gluhwein";
import Weinwanderung from "views/Weinwanderung";
import Fotoshooting from "views/Fotoshooting";
import Gin from "views/Gin";
import Patenschaft from "views/Patenschaft";
import Aperolwanderung from "views/Aperol";
Amplify.configure(awsExports);

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            path="/impressum"
            render={(props) => <Impressum {...props} />}
          />
          <Route path="/events/yoga" render={(props) => <Yoga {...props} />} />
          <Route path="/events/meet" render={(props) => <Meet {...props} />} />
          <Route path="/events/wanderung" render={(props) => <Wanderung {...props} />} />
          <Route path="/events/aperolwanderung" render={(props) => <Aperolwanderung {...props} />} />
          <Route path="/events/geburtstag" render={(props) => <Geburtstag {...props} />} />
          <Route path="/events/gluhwein" render={(props) => <Gluhwein {...props} />} />
          <Route path="/events/weinwanderung" render={(props) => <Weinwanderung {...props} />} />
          <Route path="/events/gin" render={(props) => <Gin {...props} />} />
          <Route path="/events" render={(props) => <Events {...props} />} />
          <Route path="/datenschutz" render={(props) => <Datenschutz {...props} />} />
          <Route path="/fotoshooting" render={(props) => <Fotoshooting {...props} />} />
          <Route path="/agb" render={(props) => <Agb {...props} />} />
          <Route path="/animals" render={(props) => <Animals {...props} />} />
          <Route path="/anfahrt" render={(props) => <Anfahrt {...props} />} />
          <Route path="/contact" render={(props) => <Contact {...props} />} />
          <Route path="/gutschein" render={(props) => <Gutschein {...props} />} />
          <Route path="/patenschaft" render={(props) => <Patenschaft {...props} />} />
          <Route path="/" render={(props) => <Index {...props} />} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default App;
