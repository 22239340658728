import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Location from "../components/Location";
import { Container, Row, Col } from "reactstrap";
function Anfahrt() {
    document.documentElement.classList.remove("nav-open");
    return (
        
        <>
            <IndexNavbar transparentUntilScrolled={false} />

            <Container className="pt-4 mt-4">
                <Row>
                    <Col>
                        <h1 className="title">Anfahrt und Adresse</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <address>
                            <strong>Alpakas vom Rosenhof<br />
                            Alte Wormser Str. 9<br />
                            68623 Lampertheim<br />
                            </strong>
                        </address>
                    </Col>
                </Row>
                <div className="article-content">
                    <h3>Parken</h3>
                    <p>Parkmöglichkeiten findet ihr direkt an der Koppel in der Nähe des grünen Zeltes. Alternativ könnt ihr auch am Rand des asphaltierten Feldweges parken.</p>
                </div>

                <div className="article-content">
                    <h3>Von Worms kommend:</h3>
                    <p>Nach dem Ortseingang Rosengarten die erste Möglichkeit rechts ab fahren, bei der nächsten Möglichkeit nach ca 100 Metern wieder rechts abbiegen. Nach ca. 800 Metern findet ihr unsere Koppel auf der linken Seite des Feldweges.</p>
                </div>
                <div className="article-content">
                    <h3>Von Lampertheim kommend:</h3>
                    <p>Nach dem Ortseingang auf Höhe des ersten Blitzers direkt links abbiegen. Am Ende des Feldweges wieder links abbiegen und nach ca. 300 Metern findet ihr uns auf der linken Seite.</p>
                </div>
                <div className="article-content">
                    <h3> Von Bürstadt (B47) kommend:</h3>
                    <p>In Rosengarten an der zweiten Ampel links abbiegen in Richtung Lampertheim. Nach der Bushaltestelle auf der rechten Seite direkt rechts abbiegen. Am Ende des Feldweges wieder links abbiegen und nach ca. 300 Metern findet ihr uns auf der linken Seite.
                    </p>
                </div>
                <div className="article-content mt-5">
                    <Location />
                </div>
            </Container>

        </>
    )
}

export default Anfahrt;