import React from "react";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Container } from "reactstrap";
// index sections

import SectionIntroText from "./index-sections/SectionIntroText";
import SectionVideo from "./index-sections/SectionVideo";
import SectionYoga from "./index-sections/SectionYoga";
import SectionWine from "./index-sections/SectionWine";

function Index() {
  return (
    <>
      <IndexNavbar transparentUntilScrolled={true} />
      <LandingPageHeader />
    
      <Container>
        <SectionIntroText />
        <SectionWine />
      
        <SectionVideo />
        
      </Container>
    </>
  );
}
export default Index;
