import React from "react";
// reactstrap components
import { Container, Row } from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Agb() {
  return (
    <>
      <IndexNavbar transparentUntilScrolled={false} />
      <div className="main">
        <div className="section text">
          <Container>
            <Row>
              <h1 className="title">Allgemeine Geschäftsbedingungen</h1>
            </Row>
            <h4>
              www.alpakas-vom-rosenhof.de<br />
              Stefanie Schwarz & Laura Flessa GbR<br />
              Alte Wormser Straße 9<br />
              68623 Lampertheim<br />
            </h4>
            <h2>Willkommen bei den Alpakas vom Rosenhof! </h2>

            <div className="article-content">
              <h3>§ 1 Geltungsbereich und Anbieter </h3>
              <p>(1) Die Allgemeinen Geschäftsbedingungen (nachfolgend „AGB" genannt) regeln
                a) den Verkauf von Dienstleistungen durch Stefanie Schwarz und Laura Flessa GbR (nachfolgend Anbieter) an Sie, in ihrer zum Zeitpunkt der Bestellung gültigen Fassung.
                b) den Aufenthalt aller Personen, die eine Dienstleistung von uns in Anspruch nehmen, auf unserem Betrieb zum Zeitpunkt des Aufenthalts gültigen Fassung.
              </p>
              <p>(2) Abweichende AGB des Bestellers werden zurückgewiesen.</p>
              <p>(3) Bitte lesen Sie diese Bedingungen aufmerksam, bevor Sie eine Bestellung an Stefanie Schwarz und Laura Flessa GbR aufgeben oder ein Event auf unserem Betrieb buchen. Durch Aufgabe einer Bestellung an Stefanie Schwarz und Laura Flessa GbR erklären Sie sich mit der Anwendung dieser Verkaufsbedingungen auf Ihre Bestellung sowie Aufenthaltsbedingungen einverstanden.
                Auf den Alpakas vom Rosenhof bieten wir Ihnen den Verkauf von folgenden Produkten an:
                Alpakawanderungen, Meet and Greet mit den Alpakas, Yoga auf der Alpakawiese, Kindergeburtstage und weitere Events auf der Alpakawiese / mit den Alpakas.
              </p>
            </div>

            <div className="article-content">
              <h3>§ 2 Zustandekommen des Vertrages</h3>
              <p>(1) Verträge auf diesem Portal können ausschließlich in deutscher Sprache abgeschlossen werden.</p>
              <p>(2) Vertragspartner ist die Stefanie Schwarz und Laura Flessa GbR auf der einen Seite. Vertragspartner ist jeder Besucher, der eine Dienstleistung in Anspruch nimmt. Wenn Gruppen von Schulen / Stiftungen / Vereinen / Firmen ein Event gebucht haben, ist deren jeweilige Institution Vertragspartner.</p>
              <p>(3) Der Vertragspartner muss das 18. Lebensjahr vollendet haben.</p>
              <p>(4) Die Präsentation der Dienstleistungen auf der Internetseite www.alpakas-vom-rosenhof.de stellt kein rechtlich wirksames Angebot dar. Durch die Präsentation der Ware wird der Kunde lediglich dazu aufgefordert, ein Angebot zu machen.</p>
              <p>(5) Ihre Bestellung bzw. Terminbuchung stellt ein Angebot an Stefanie Schwarz & Laura Flessa GbR zum Abschluss eines Kaufvertrages dar. Der Besteller gibt ein verbindliches Angebot ab, wenn er den Online-Buchungsprozess unter Eingabe der dort verlangten Angaben durchlaufen hat und im letzten Bestellschritt den Button "Termin buchen" anklickt.</p>
              <p>(6) Der Kaufvertrag zwischen dem Anbieter und dem Besteller kommt erst durch eine Annahmeerklärung des Anbieters zustande. Diese erfolgt durch Zusendung einer Terminbestätigung per E-Mail. Beachten Sie, dass die Bestätigung über den Eingang Ihrer Bestellung keine Annahmeerklärung im zuvor genannten Sinne darstellt.</p>
              <p>(7) Ihre Bestellungen werden bei uns nach Vertragsschluss gespeichert. Sollten Sie Ihre Unterlagen zu Ihren Bestellungen verlieren, wenden Sie sich bitte per E-Mail oder Telefon an uns. Wir senden Ihnen eine Kopie der Bestelldaten zu.</p>
            </div>

            <div className="article-content">
              <h3>§ 3 Hausordnung</h3>
              <p>(1) Zutritt <br />
                Unser Gelände darf nur mit unserer ausdrücklichen Zustimmung betreten werden. Kinder unter 10 Jahren dürfen sich auf dem Gelände ausschließlich mit einem Erwachsenen aufhalten.</p>
              <p>(2) Hunde<br />
                Hunde sind auf unserem Gelände nicht gestattet.</p>
              <p>(3) Wanderungen / Events mit den Tieren<br />
                Wir behalten uns das Recht vor, unsere Touren bei Gewitter oder Gewittermeldungen, Stürmen, starkem Regen oder Schneefall und Glatteis auch kurzfristig abzusagen. Wir versuchen in diesem Falle, einen Ersatztermin zu finden.</p>
              <p>(4) Sicherheit<br />
                Wir behalten uns das Recht vor, vor oder während eines Events Personen ohne Rückerstattung auszuschließen, sofern sie die Sicherheit unserer Tiere oder der anderen Gäste gefährden, sowie sich selbst.
                Der Gast ist eigenständig dafür verantworlich, sich mit wetterfester Kleidung wie Gummistiefel, Regenjacken oder auch Sonnenschutz einzudecken. Sonnenschirme müssen auf unseren Events immer geschlossen werden, um die Sicherheit aller nicht zu gefährden.
                Wir haften nicht für Schäden, die aufgrund des Wetters und der örtlichen Gegebenheiten entstehen.</p>
              <p>(5) Haftung für verlorene Gegenstände<br />
                Wir können keine Haftung für verlorene oder abgestellte Gegenstände unserer Gäste übernehmen.</p>
              <p>(6) Zustimmung der AGB's<br />
                Die Teilnahme an unseren Events erfolgt immer nur unter Einwilligung in unsere Allgemeinen Geschäftsbedingungen.</p>
            </div>

            <div className="article-content">
              <h3>§ 4 Preise, Versandkosten und Stornogebühren</h3>
              <p>(1) Unsere Preise beinhalten die jeweils gültige gesetzliche Mehrwertsteuer und verstehen sich ohne Versandkostenpauschale oder Versandkostenzuschlag. Die Versandkostenzuschläge variieren je nach Lieferart und Artikelbeschaffenheit.</p>
              <p>(2) Es gelten die Preise zum Zeitpunkt der Bestellung.< br />
                Der Vertragspartner kann eine Terminbuchung bis fünf Tage vor Stattfinden des gebuchten Events kostenfrei stornieren.</p>
              <p>(4) Sollte der Vertragspartner die Stornierung innerhalb von 24 Stunden bis fünf Tage vor dem gebuchten Event stornieren, wird eine Stornogebühr in Höhe des halben Preises des gebuchten Events berechnet.</p>
              <p>(5) Sollte der Vertragspartner die Stornierung in weniger als 24 Stunden vor dem gebuchten Event vornehmen oder ohne Stornierung zum gebuchten Termin nicht erscheinen, so muss er eine Stornogebühr in Höhe des vollen Preises des gebuchten Events bezahlen.</p>
              <p>(6) Sollte ein Vertragspartner ein Event vorzeitig beenden, so muss er dennoch den vollen Preis für dieses Event bezahlen.</p>
            </div>

            <div className="article-content">
              <h3>§ 5 Zahlung</h3>
              <p>(1) Der Besteller kann die Ware durch folgende Zahlungsarten bezahlen:
                <ul>
                  <li>Barzahlung gegen eine Quittung</li>
                  <li>EC oder Kreditkarte</li>
                  <li>Auf Rechnung</li>
                </ul>
              </p>
              <p>Zahlung auf Rechnung ist nur für Verbraucher ab 18 Jahren möglich. Die Lieferadresse, die Hausanschrift und die Rechnungsadresse müssen identisch sein und in folgenden Ländern liegen:
                < br />Deutschland< br />
              </p>
              <p>Bei Zahlung auf Rechnung wird zuzüglich eventuell anfallender Versandkosten für den Komplettversand einmalig eine Gebühr von 2 € (zwei Euro) inklusive der jeweils anwendbaren Mehrwertsteuer pro Lieferung, berechnet. Der Besteller wird vor Vertragsschluss stets gesondert darüber informiert, ob diese Gebühr anfällt.</p>
              <p>Soweit der Rechnungsbetrag aus vom Besteller zu vertretenden Gründen nicht zum Fälligkeitszeitpunkt gezahlt wird, erhebt die Stefanie Schwarz und Laura Flessa GbR einen pauschalierten Schadensersatz in Höhe von 5 € (fünf Euro). Der Besteller kann nachweisen, dass ein Schaden überhaupt nicht entstanden oder wesentlich niedriger ist als die Pauschale.
                Bei Zahlung auf Rechnung sowie in sonstigen Fällen bei berechtigtem Anlass prüft und bewertet die Stefanie Schwarz & Laura Flessa GbR die Datenangaben der Besteller.</p>
              <p>(2) Bestimmte Zahlungsarten können im Einzelfall von dem Anbieter ausgeschlossen werden.</p>
              <p>(3) Dem Besteller ist nicht gestattet die Ware durch das Senden von Bargeld oder Schecks zu bezahlen.</p>
              <p>(4) Sollte der Besteller die Zahlungsart Barzahlung wählen, muss der Betrag bis spätestens zum Tag des gebuchten Events bezahlt werden, ansonsten behält sich der Anbieter die Geltendmachung des Verzugschadens vor.</p>
              <p>(5) Sollte der Besteller die Zahlungsart Rechnung wählen, verpflichtet sich der Besteller, den Rechnungsbetrag innerhalb von 14 Tagen nach Warenversand, ohne jeglichen Abzug von Skonto zu begleichen.</p>
              <p>(6) Sollte der Besteller mit der Zahlung in Verzug kommen, so behält sich der Anbieter die Geltendmachung des Verzugschadens vor.</p>
            </div>

            <div className="article-content">
              <h3>§ 6 Aufrechnung und Zurückbehaltungsrecht</h3>
              <p>(1) Dem Besteller steht das Recht zur Aufrechnung nur zu, wenn die Gegenforderung des Bestellers rechtskräftig festgestellt worden ist oder von dem Anbieter nicht bestritten wurde.</p>
              <p>(2) Der Besteller kann ein Zurückbehaltungsrecht nur ausüben, soweit Ihre Gegenforderung auf demselben Vertragsverhältnis beruht.</p>
            </div>

            <div className="article-content">
              <h3>§ 7 Eigentumsvorbehalt</h3>
              <p>Stefanie Schwarz und Laura Flessa GbR behält sich die Erbringung der Dienstleistung bis zur vollständigen Bezahlung vor.</p>
            </div>

            <div className="article-content">
              <h3>§ 8 Haftungsbegrenzung (Produkte)</h3>
              <p>(1) Der Anbieter haftet für Schadensersatzansprüche des Bestellers aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten, sowie für sonstige Schäden, die auf ihrer vorsätzlichen oder grob fahrlässigen Pflichtverletzung, oder einer der gesetzlichen Vertreter oder Erfüllungsgehilfen des Anbieters beruhen.</p>
              <p>(2) Als wesentliche Vertragspflichten gelten Pflichten, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.</p>
              <p>(3) Der Anbieter haftet für Verletzungen wesentlicher Vertragspflichten, die auf vertragstypischen, vorhersehbaren Schäden beruhen, vorausgesetzt der Schaden wurde einfach fahrlässig verursacht. Diese Begrenzung gilt nicht für Schadensersatzansprüche des Bestellers, die auf einer Verletzung des Lebens, des Körpers oder der Gesundheit beruhen.</p>
              <p>(4) Die Vorschriften des Produkthaftungsgesetzes bleiben unberührt.</p>
              <p>(5) Soweit die Haftung von der Stefanie Schwarz und Laura Flessa GbR ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.</p>
            </div>

            <div className="article-content">
              <h3>§ 9 Widerrufsbelehrung</h3>
              <p>(1) Ist der Besteller ein Verbraucher, so hat er ein Widerrufsrecht nach Maßgabe der folgenden Bestimmungen:</p>
              <p>(2) Widerrufsrecht < br />
                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Ware (oder die letzte Ware, Teilsendung oder Stück im Falle eines Vertrags über mehrere Waren einer einheitlichen Bestellung oder die Lieferung einer Ware in mehreren Teilsendungen oder Stücken) in Besitz genommen haben, ohne Angabe von Gründen zu widerrufen.
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns:</p>
              Stefanie Schwarz und Laura Flessa GbR< br />
              Alte Wormser Straße 9< br />
              68623 Lampertheim< br />
              E-Mail: info@alpakas-vom-rosenhof.de< br />
              <p>mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden und Sie die Waren innerhalb der unten definierten Frist zurückgesendet haben.</p>
              <p>(3) Folgen des Widerrufs< br />
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
                Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an< br />
                Stefanie Schwarz und Laura Flessa GbR< br />
                Alte Wormser Straße 9< br />
                68623 Lampertheim< br />
                E-Mail: info@alpakas-vom-rosenhof.de< br />
                zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 14 Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
              <p>(4) Ausnahmen vom Widerrufsrecht< br />
                Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
                Das Widerrufsrecht besteht nicht bzw. erlischt bei folgenden Verträgen:
                zur Lieferung von Waren, die aus Gründen des Gesundheitsschutzes oder aus Hygienegründen nicht zur Rückgabe geeignet sind und deren Versiegelung nach der Lieferung entfernt wurde oder die nach der Lieferung aufgrund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;
                zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der Lieferung entfernt wurde;
                zur Lieferung von Waren, die nach Kundenspezifikation angefertigt werden oder eindeutig auf die persönlichen Bedürfnisse zugeschnitten sind
                zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;
                bei Dienstleistungen, wenn den Alpakas vom Rosenhof diese vollständig erbracht hat und Sie vor der Bestellung zur Kenntnis genommen und ausdrücklich zugestimmt haben, dass wir mit der Erbringung der Dienstleistung beginnen können und Sie Ihr Widerrufsrecht bei vollständiger Vertragserfüllung verlieren;
                zur Lieferung von Zeitungen, Zeitschriften oder Illustrierte, mit Ausnahme von Abonnement-Verträgen; und
                zur Lieferung alkoholischer Getränke, deren Preis beim Abschluss des Kaufvertrags vereinbart wurde, deren Lieferung aber erst nach 30 Tagen erfolgen kann und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat.
              </p>
            </div>

            <div className="article-content">
              <h3>§ 10 Ausschluss des Widerrufsrechts</h3>
              <p>(1) Das Widerrufsrecht besteht nicht bei Verträgen
                <ul>
                  <li>zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;</li>
                  <li>zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;</li>
                </ul>
              </p>
              <p>(2) Das Widerrufsrecht erlischt vorzeitig bei Verträgen
                <ul>
                  <li>zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde;</li>
                  <li>zur Lieferung von Waren, wenn diese nach der Lieferung auf Grund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;</li>
                  <li>zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.</li>
                </ul>
              </p>
            </div>

            <div className="article-content">
              <h3>§ 11 Datenschutz</h3>
              <p>(1) Sollten personenbezogene Daten (z.B. Name, Anschrift, E-Mail-Adresse) erhoben werden, verpflichten wir uns dazu, Ihr vorheriges Einverständnis einzuholen. Wir verpflichten uns dazu, keine Daten an Dritte weiterzugeben, es sei denn, Sie haben zuvor eingewilligt.</p>
              <p>(2) Wir weisen darauf hin, dass die Übertragung von Daten im Internet (z. B. per E-Mail) Sicherheitslücken aufweisen kann. Demnach kann ein fehlerfreier und störungsfreier Schutz der Daten Dritter nicht vollständig gewährleistet werden. Diesbezüglich ist unsere Haftung ausgeschlossen.</p>
              <p>(3) Dritte sind nicht dazu berechtigt, Kontaktdaten für gewerbliche Aktivitäten zu nutzen, sofern der Anbieter den betroffenen Personen vorher eine schriftliche Einwilligung erteilt hat.</p>
              <p>(4) Sie haben jederzeit das Recht, von den Alpakas vom Rosenhof über den Sie betreffenden Datenbestand vollständig und unentgeltlich Auskunft zu erhalten.</p>
              <p>(5) Des Weiteren besteht ein Recht auf Berichtigung/Löschung von Daten/Einschränkung der Verarbeitung für den Nutzer.</p>
            </div>

            <div className="article-content">
              <h3>§ 12 Cookies</h3>
              <p>(1) Zur Anzeige des Produktangebotes kann es vorkommen, dass wir Cookies einsetzen. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden.</p>
              <p>(2) Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.</p>
              <p>(3) Durch den Einsatz von Cookies kann den Nutzern dieser Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.</p>
              <p>(4) Wir weisen Sie darauf hin, dass einige dieser Cookies von unserem Server auf Ihr Computersystem überspielt werden, wobei es sich dabei meist um so genannte sitzungsbezogene Cookies handelt. Sitzungsbezogene Cookies zeichnen sich dadurch aus, dass diese automatisch nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht werden. Andere Cookies verbleiben auf Ihrem Computersystem und ermöglichen es uns, Ihr Computersystem bei Ihrem nächsten Besuch wieder zu erkennen (sog. dauerhafte Cookies).</p>
              <p>(5) Sie können der Speicherung von Cookies widersprechen, hierzu steht Ihnen ein Banner zu Verfügung dem Sie widersprechen/annehmen können.</p>
              <p>(6) Selbstverständlich können Sie Ihren Browser so einstellen, dass keine Cookies auf der Festplatte abgelegt werden bzw. bereits abgelegte Cookies wieder gelöscht werden. Die Anweisungen bezüglich der Verhinderung sowie Löschung von Cookies können Sie der Hilfefunktion Ihres Browsers oder Softwareherstellers entnehmen.</p>
            </div>

            <div className="article-content">
              <h3>§ 13 Gerichtsstand und anwendbares Recht</h3>
              <p>(1) Für Meinungsverschiedenheiten und Streitigkeiten anlässlich dieses Vertrages gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>
              <p>(2) Alleiniger Gerichtsstand bei Bestellungen von Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen ist der Sitz des Anbieters.</p>
            </div>

            <div className="article-content">
              <h3>§ 14 Schlussbestimmungen</h3>
              <p>(1) Vertragssprache ist deutsch.</p>
              <p>(2) Wir bieten keine Produkte oder Dienstleistungen zum Kauf durch Minderjährige an. Unsere Produkte für Kinder können nur von Erwachsenen gekauft werden. Falls Sie unter 18 sind, dürfen Sie den Alpakas vom Rosenhof nur unter Mitwirkung eines Elternteils oder Erziehungsberechtigten nutzen.</p>
              <p>(3) Wenn Sie diese AGB verletzen und wir unternehmen hiergegen nichts, sind wir weiterhin berechtigt, von unseren Rechten bei jeder anderen Gelegenheit, in der Sie diese Verkaufsbedingungen verletzen, Gebrauch zu machen.</p>
              <p>(4) Wir behalten uns das Recht vor, Änderungen an unserer Webseite, Regelwerken, Bedingungen einschließlich dieser AGB jederzeit vorzunehmen. Auf Ihre Bestellung finden jeweils die Verkaufsbedingungen, Vertragsbedingungen und AGB Anwendung, die zu dem Zeitpunkt Ihrer Bestellung in Kraft sind, es sei denn eine Änderung an diesen Bedingungen ist gesetzlich oder auf behördliche Anordnung erforderlich (in diesem Fall finden sie auch auf Bestellungen Anwendung, die Sie zuvor getätigt haben). Falls eine Regelung in diesen Verkaufsbedingungen unwirksam, nichtig oder aus irgendeinem Grund undurchsetzbar ist, gilt diese Regelung als trennbar und beeinflusst die Gültigkeit und Durchsetzbarkeit der verbleibenden Regelungen nicht.</p>
              <p>(5) Die Unwirksamkeit einer Bestimmung berührt die Wirksamkeit der anderen Bestimmungen aus dem Vertrag nicht. Sollte dieser Fall eintreten, soll die Bestimmung nach Sinn und Zweck durch eine andere rechtlich zulässige Bestimmung ersetzt werden, die dem Sinn und Zweck der unwirksamen Bestimmung entspricht.</p>
            </div>
          </Container>
        </div>
      </div >

    </>
  );
}
export default Agb;
