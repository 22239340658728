import React from "react";
// reactstrap components
import { Container, Card, Row, Col, Button } from "reactstrap";

function SectionWine() {
    return (
        <div className="section text-center">
            <Container>
                <Row  className="align-items-center">



                    <Col className="ml-auto m-3" md="5">
                        <Row >
                            <Col className="description">
                                <h3 className="info-title">Weinwanderung</h3>
                                <h5>
                                Wir wandern durch die schönen Felder in Lampertheim Rosengarten und verkosten Weine aus der Region.
                                </h5>
                            </Col>
                        </Row>
                        <Row className="m-3">
                            <Col>
                                <Button
                                    className="btn-round"
                                    color="primary"
                                    href="/events/weinwanderung"
                                    outline
                                >
                                    mehr Infos
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                    <Col className="ml-auto m-3" md="5">
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src={"/img/events/weinwanderung-2.jpg"} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}
export default SectionWine;
