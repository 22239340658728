import React from "react";
// reactstrap components
import { Container,Card, Row, Col, Button} from "reactstrap";
import { Link } from "react-router-dom";

function SectionIntroText() {
  return (
    <div className="section text-center">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">Willkommen bei den Alpakas vom Rosenhof</h2>
          </Col>
        </Row>
        <Row>
        <Col className="ml-auto m-3" md="5">
                                <Card className="card-plain">
                                    <img className="img-fluid" alt="..." src={"/img/startseite.jpg"} />
                                </Card>
                            </Col>
          <Col className="ml-auto m-3" md="5">
            <Row>
              <h5 className="description">
                Wir freuen uns, mit euch ein Alpaka-Abenteuer zu erleben.
                <br /> <br />
                Unser Hof befindet sich im Lampertheimer Ortsteil "Rosengarten" nahe der Wormser Rheinbrücke.
                <br />
                <br />Wenn ihr Interesse daran habt, <Link to="/animals">unsere Tiere</Link> live kennenzulernen, findet ihr <Link to="/events">hier</Link> unsere aktuellen Events und Termine.
                <br />
                <br />Schaut doch mal auf unserer Instagram-Seite vorbei, hier posten wir regelmäßige Updates und Bilder rund um unsere Tiere.
              </h5>
              <br />
            </Row>
            <Row>
              <Col className="ml-auto mr-auto">
                <Button
                  className="btn-round"
                  color="primary"
                  href="https://www.instagram.com/alpakas_vom_rosenhof"
                  target="_blank"
                  outline
                >
                  <i className="fa fa-instagram" />
                  Instagram
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default SectionIntroText;
