import React from "react";
import { Field, reduxForm } from "redux-form";
import {
  FormGroup,
  Button,
  Input,
  Label,
  Toast,
  ToastHeader,
  ToastBody,
  Container,
  Row,
  Col,
} from "reactstrap";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      showToast: false,
    };
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta, type, rows }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <Label>{label}</Label>
        <Input type={type} {...input} rows={rows} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  toggle() {
    this.setState({
      showToast: !this.state.showToast,
    });
  }

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
    this.toggle();
  };

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h1 className="title">Kontakt</h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <form
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                className="ui form error"
              >
                <FormGroup>
                  <Field
                    name="name"
                    component={this.renderInput}
                    label="Dein Name"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="email"
                    component={this.renderInput}
                    label="E-Mail"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="message"
                    component={this.renderInput}
                    label="Deine Nachricht"
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    id="submit"
                    className="btn btn-primary"
                    disabled={this.state.showToast}
                  >
                    Absenden
                  </Button>
                </FormGroup>
              </form>
              <Toast isOpen={this.state.showToast}>
                <ToastHeader>Vielen Dank für deine Nachricht</ToastHeader>
                <ToastBody>Wir werden uns bald bei dir melden!</ToastBody>
              </Toast>
            </Col>
            <Col className="ml-auto mr-auto" md="6">
              <img
                className="img-fluid"
                alt="..."
                src={"img/contact-form.jpg"}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "Bitte einen Namen eingeben";
  }
  if (!formValues.email) {
    errors.email = "Bitte eine Email eingeben";
  }

  if (!formValues.message) {
    errors.message = "Du must eine Nachricht eingeben";
  }
  return errors;
};
export default reduxForm({ form: "contactForm", validate })(ContactForm);
