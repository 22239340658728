import IndexNavbar from "components/Navbars/IndexNavbar.js";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, CardLink } from "reactstrap";
import { Link } from "react-router-dom";

const Wanderung = (props) => {
    return (<>
        <IndexNavbar transparentUntilScrolled={false} />
        <div className="header-1">

            <div
                className="page-header"
                style={{
                    backgroundImage: `url(/img/events/wanderung-header.jpg)`
                }}
            >
                <div className="filter" />
                <div className="content-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto" md="12">
                                <h2 className="title"><strong></strong></h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
        <Container className="pt-4 mt-4">

            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3>Wie gestaltet sich der Ablauf einer Wanderung?</h3>
                        <p>Treffpunkt für die Tour ist direkt an der Alpakawiese in der Alten Wormser Straße 9 in 68623
                            Lampertheim Rosengarten. Gelegenheit zum Parken besteht direkt an der Wiese. </p>
                        <p>
                            Wenn alle Teilnehmer anwesend sind, gehen wir gemeinsam auf die Wiese und holen die Tiere. Hierbei
                            bekommt der Teilnehmer einen Einblick in die Haltung und Fütterung, auch für ein gegenseitiges
                            Beschnuppern von Mensch und Tier ist Zeit eingeplant.
                        </p>
                        <p>
                            Wenn alle Tiere aufgehalftert sind, laufen wir los. Wir wandern auf Wiesen und Feldwegen für ca.
                            60 Minuten. Zeit für Pausen und Bilder ist ebenfalls eingeplant.
                            Wenn wir den Hof wieder erreichen, werden die Alpakas und Lamas auf ihre Wiese entlassen. Generell sollte man 90
                            Minuten Zeit einplanen.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3>Was kostet eine Wanderung?</h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="6">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">Wanderung ab 10 Jahren mit eigenem Tier</h6>
                            <CardTitle tag="h3">€ 25.00</CardTitle>
                            <p className="card-description">
                                Man kann die Tour mit einem eigenen Alpaka oder Lama laufen.
                            </p>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="ml-auto mr-auto" md="6">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">Wanderung als Begleitperson – Kinder ab 10 Jahren oder Erwachsene*r</h6>
                            <CardTitle tag="h3">€ 15.00</CardTitle>
                            <p className="card-description">
                                Es besteht die Möglichkeit, als Begleitperson (ohne das Führen eines eigenen Tiers) mitzulaufen.
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="6">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">Kinder bis 9 Jahre</h6>
                            <CardTitle tag="h3">kostenlos</CardTitle>
                            <p className="card-description">
                                Kinder bis 9 Jahren dürfen <strong>nur als Begleitperson</strong> mitlaufen.<br />
                                Für Kinder bis 9 Jahren ist die Wanderung kostenfrei. Auf einen Erwachsenen dürfen jedoch maximal 2
                                Kinder mitlaufen.
                            </p>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="ml-auto mr-auto" md="6">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">exklusive Gruppenwanderung</h6>
                            <CardTitle tag="h3">€ 250.00</CardTitle>
                            <p className="card-description">
                                Pauschalpreis für eine Wanderung für bis zu 10 Personen (maximal 10 Tiere). Zusätzliche Begleitpersonen kosten 15 Euro pro Person.
                                <br></br><br></br>
                                <p>Schickt uns am besten eine E-Mail, wenn ihr eine exklusive Gruppenwanderung buchen möchtet.</p>
                            </p>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col className="ml-auto m-3" md="5">
                    <Card className="card-plain">
                        <img className="img-fluid" alt="..." src={"/img/events/wanderung-1.jpg"} />
                    </Card>
                </Col>
                <Col className="ml-auto m-3" md="5">
                    <Card className="card-plain">
                        <img className="img-fluid" alt="..." src={"/img/events/wanderung-2.jpg"} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3>Was muss ich noch vor einer Wanderung wissen?</h3>
                        <ul>
                            <li>Der Großteil unserer Wandertiere sind Alpakas. Zusätzlich nehmen wir auf jeder Wanderung wenige Lamas mit, da diese unterwegs die Führung der Gruppe übernehmen. Unser Team entscheidet vor jeder Wanderung, welche Gäste die sanften Riesen führen dürfen.</li>
                            <li>Alpakas haben grundsätzlich großen Respekt und teilweise auch Angst vor Hunden, deswegen
                                können wir leider keine Hunde auf unserem Gelände gestatten.</li>
                            <li>Bring am besten wetterfeste Kleidung mit: Bei Regenwetter eine Regenjacke (keinen Schirm!) und
                                wasserfeste Schuhe / Gummistiefel, im Sommer ggf. eine Kopfbedeckung und ausreichend
                                Sonnencreme, mit.</li>
                            <li>Bei extremen Wetterbedingungen (Sturm, Hagel, Dauerregen, Glatteis, extremer Hitze) müssen
                                wir das Wohlergehen der Tiere immer in den Vordergrund stellen und behalten uns das Recht vor,
                                Wanderungen auch kurzfristig abzusagen. Selbstverständlich sorgen wir bei einer ausgefallenen
                                Wanderung für Alternativtermine.</li>
                            <li>Bitte beachte unsere Regelungen für Stornierungen in unseren <CardLink to="/agb" tag={Link}>AGBs</CardLink>.</li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3>Firmenevents, größere Gruppen oder spezielle Wünsche?</h3>
                        <p>Am besten ihr kontaktiert uns per E-Mail (info@alpakas-vom-rosenhof.de). Wir melden uns bei euch, um alles Weitere zu besprechen. </p>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto m-3" md="10">
                    <Card className="card-plain">
                        <img className="img-fluid" alt="..." src={"/img/events/wanderung-3.jpg"} />
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col>
                    <h3><strong>Termin buchen</strong></h3>
                    <iframe id="etifr" title="meet_calendar" src="https://www.etermin.net/alpakas-vom-rosenhof?servicegroupid=101941" height="1600px" width="100%" scrolling="no" frameBorder="0"></iframe>
                </Col>
            </Row>

        </Container>
    </>);
}

export default Wanderung;