import { Component } from "react";

class Location extends Component{
   

  render(){
    return (
    <iframe
            title="Anfahrt"
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58448.63361443398!2d8.339199498250746!3d49.65262091605948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47962bf57a78e0e3%3A0x641e7ffc1d7f8061!2sAlpakas%20vom%20Rosenhof!5e0!3m2!1sen!2sde!4v1623173772116!5m2!1sen!2sde"
             width="100%"
             height="450"
             frameBorder="0"
             style={{ border: 0 }}
             allowFullScreen=""
             aria-hidden="false"
             tabIndex="0"
             loading="lazy"
           />
    )
  }

}
export default Location;
