import React from "react";
import { Container } from "reactstrap";

import EventCard from "./EventCard";
import "./EventList.css";

const EventList = (props) => {
    const events = props.events.map((event) => {
        return (

                <EventCard key={event.id} event={event} />

        )
    });
    return (<Container className="section section-project cd-section" >{events}</Container>)
};

export default EventList;
