import React from "react";
import { Container, Row, Col } from "reactstrap";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Gutschein() {
    document.documentElement.classList.remove("nav-open");
    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-4">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="6">
                        <h2>Gutscheine</h2>
                    </Col>
                </Row>
                <Row className="content-center">
                    <Col className="ml-auto mr-auto text-center" md="5">
                        <Row>
                            <Col><h4>Für jedes Event können Gutscheine bei uns erworben werden.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-4">
                                <h5>
                                    Die Gutscheine sind insgesamt drei Jahre ab Zeitpunkt des Kaufs einlösbar.
                                    Schreib uns gerne eine E-Mail mit deiner Adresse und der Art des gewünschten
                                    Gutscheins, dann kümmern wir uns zeitnah um das Ausfüllen und Versenden des
                                    Gutscheins.</h5>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="ml-auto m-3" md="5">
                        <img className="img-fluid" alt="..." src={"/img/gutschein.jpg"} />
                    </Col>
                </Row>

                <Row>

                    <Col>
                        <h3>Preis für einen Gutschein</h3>
                        <ul>
                            <li>über eine Alpakawanderung mit eigenem Alpaka ab 10 Jahren: 27,-</li>
                            <li>über eine Alpakawanderung als Begleitperson über 10 Jahren: 17,-</li>
                            <li>über eine Alpakawanderung für 2 Personen über 10 Jahren mit einem Alpaka: 42,-</li>
                            <li>über eine Alpakawanderung für 2 Personen über 10 Jahren mit zwei Alpakas: 52,-</li>
                            <li>über ein Meet and Greet ab 12 Jahren: 14,-</li>
                            <li>über ein Meet and Greet zwischen 4 und 11 Jahren: 10,-</li>
                            <li>über eine Stunde Yoga auf der Alpakawiese: 22,-</li>
                            <li>über eine Weinwanderung (2 Personen mit einem Alpaka): 72,-</li>
                            <li>über eine Glühweinwanderung (2 Personen mit einem Alpaka): 72,-</li>
                        </ul>
                        <p>Die Preise setzen sich zusammen  aus dem Preis für das
                            jeweilige Event (19 % Mehrwertsteuer enthalten) und einer Bearbeitungsgebühr plus Versandgebühr von 2€.</p>
                        <p>Du bekommst nach deiner Gutscheinbestellung eine Rechnung per E-Mail zugesendet. Nach Zahlungseingang wird der Gutschein versendet. Alternativ kann der Gutschein auch in unserem Hofladen erworben werden.</p>
                        <p>Es können auch Gutscheine über einen beliebigen Betrag erstellt werden, die dann für ein
                            Event eurer Wahl eingelöst werden können.</p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Gutschein;
