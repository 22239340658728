import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const Yoga = (props) => {
    return (<>
        <IndexNavbar transparentUntilScrolled={false} />
        <Container className="pt-4 mt-4">
            <Row>
                <Col className="ml-auto mr-auto text-center title " >
                    <h2>Yoga auf der Alpakawiese</h2>
                </Col>
            </Row>
            <Row>
                <Col md="5">
                    <h3>Yoga auf der Alpakawiese – Was kann ich mir darunter vorstellen?</h3>
                    <p>
                        In den frühen Morgenstunden den Tag mit einer Stunde Yoga in der Natur genießen - inmitten der
                        friedlichen und süßen Alpakas auf der Wiese.
                        Grundsätzlich unterscheidet sich das Yoga nicht von anderen Yoga – Stunden, bis auf das Ambiente.
                        Unsere Yoga Kurse finden ausschließlich in den Monaten von April bis Oktober statt – je nach
                        Witterungsverhältnissen.
                    </p>
                </Col>
                <Col className="ml-auto m-3" md="5">
                    <img className="img-fluid" alt="..." src={"/img/events/yoga-1.jpg"} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <h3>Was kostet eine Yoga – Stunde inmitten der Alpakas?</h3>
                </Col>
            </Row>
            <Row >
                <Col className="ml-auto mr-auto" md="3">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category"> Einzelstunde Yoga pro Person</h6>
                            <CardTitle tag="h3">€ 20.00</CardTitle>
                            <p className="card-description">
                                einmalige Buchung einer Stunde
                            </p>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="ml-auto mr-auto" md="3">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">10er Karte Yoga pro Person</h6>
                            <CardTitle tag="h3">€ 170.00</CardTitle>
                            <p className="card-description">
                                10 x 1 Stunde
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


            <Row className="mt-4">
                <img className="img-fluid" alt="..." src={"/img/events/yoga-2.jpg"} />
            </Row>
            <Row className="mt-4">
                <Col>
                    <h3>Was muss ich vor der Yoga – Stunde wissen?</h3>
                    <ul>
                        <li> <strong>Bei extremen Wetterbedingungen (Sturm, Hagel, Dauerregen oder matschiger Boden) kann das Yoga nicht stattfinden</strong>, da wir keine witterungsgeschützte Ausweichmöglichkeit haben. In diesem Falle senden wir dir bis spätestens eine Stunde vor Eventbeginn eine SMS.</li>
                        <li>Alpakas haben grundsätzlich großen Respekt und teilweise auch Angst vor Hunden, deswegen können wir leider keine Hunde auf unserem Gelände gestatten.</li>
                        <li>Das Yoga eignet sich für jedermann, dennoch sollte eine gewisse Grundfitness vorhanden sein. Bei körperlichen Beeinträchtigungen setze dich bitte vor Kursbuchung mit uns in Verbindung.</li>
                        <li>Eine Yoga Matte oder eine etwas dickere Picknickdecke sowie Getränke für die Stunde muss von jedem Teilnehmer mitgebracht werden. Ebenso sollten die Teilnehmer bereits in Sportkleidung / bequemer Kleidung kommen, da keine Möglichkeit zum Umziehen vorhanden ist.</li>
                         <li>Parkmöglichkeiten gibt es direkt an der Wiese.</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3><strong>Termin buchen</strong></h3>
                    <iframe id="etifr" title="meet_calendar" src="https://www.etermin.net/alpakas-vom-rosenhof?servicegroupid=109654" height="1600px" width="100%" scrolling="no" frameBorder="0"></iframe>
                </Col>
            </Row>
        </Container>
    </>)
}

export default Yoga;