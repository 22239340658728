import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Patenschaft() {
    document.documentElement.classList.remove("nav-open");
    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-5">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="6">
                        <h2>Patenschaft</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="10">
                        <div className="article-content">
                            <h3>Mit einer Patenschaft bekommst du die Möglichkeit, dein Lieblingstier für ein Jahr zu begleiten. Bei exklusiven Events hast du die Chance, dein Tier noch näher kennenzulernen.</h3>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col className="ml-auto pt-5 pb-3">
                        <img className="img-fluid" alt="..." src={"/img/paul-pate.jpg"} />
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was beinhaltet eine Patenschaft?</h3>

                            <ul>
                                <li><p>Die Patenschaft ist vom 01. Januar bis 31. Dezember gültig.</p></li>
                                <li>
                                    <p>Du erhältst eine individuelle <b>Patenschaftsurkunde</b> und wirst (wenn du möchtest) namentlich auf der Homepage als Pate genannt.</p>
                                </li>

                                <li>
                                    <p>Damit dein Tier dich immer begleiten kann, bekommst du von uns einen <b>Ansteckbutton</b>, auf dem dein Tier abgebildet ist. Für Zuhause könnt ihr euch über einen <b>Kühlschrankmagneten</b> freuen.</p>
                                </li>
                                <li>
                                    <p>Du und eine Begleitperson deiner Wahl verbringen bei einer <b>Paten-Glühweinwanderung</b> Zeit mit eurem Patentier.</p>
                                </li>
                                <li>
                                <p>Wir treffen uns für ein gemeinsames <b>Picknick</b> im Beisein der Alpakas auf der Wiese. Hier dürfen die Paten eine Begleitperson mitbringen. Für das leibliche Wohl ist gesorgt.</p>
                                </li>
                                <li>
                                <p>Zum Abschluss des Patenschaftjahres treffen sich die Paten für ein Meet&Greet. Nachdem die Alpakas ihr Müsli verspeist haben, dürft ihr euch euer Essen beim Sortieren der Alpakawolle verdienen.
                                    Beim <b>gemütlichen Zusammensitzen</b> in unserer Stube bereiten wir die Wolle für die Weiterverarbeitung vor. Da in unserer Stube nur begrenzt Sitzplätze vorhanden sind, können wir zu diesem Event ausschließlich die Paten einladen.</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="article-content">
                            <h3>Wie kannst du Pate werden?</h3>
                        </div>
                        <p>Auf der <a href="/animals">Unsere Tiere</a> Seite bekommst du einen Überblick, für welche Tiere wir eine Patenschaft anbieten. Hast du dich für ein Tier entschieden, schreib uns eine Nachricht über das <a href="/contact">Kontaktformular</a> oder per E-Mail.</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="article-content">
                            <h3>Termine 2024</h3>
                        </div>
                        <p>Für unsere Paten haben wir im Jahr 2024 die folgenden exklusiven Termine geplant:
                            </p>

                            <ul>
                                <li>10.02.2024 - 15:00 Paten-Glühweinwanderung</li>
                                <li>15.06.2024 - 17:00 Paten-Picknick</li>
                                <li>30.11.2024 - 14:00 Meet&Greet und Wollverarbeitung</li>
                            </ul>
                    </Col>
                </Row>

                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was kostet eine Patenschaft?</h3>
                            <Col className="ml-auto mr-auto" md="12">
                                <Card className="card-pricing card-plain">
                                    <CardBody>
                                        <h6 className="card-category">Patenschaft für ein Kalenderjahr</h6>
                                        <CardTitle tag="h3">€ 199.00</CardTitle>
                                        <p className="card-description">

                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Patenschaft;
