import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardLink } from "reactstrap";
import { Link } from "react-router-dom";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Weinwanderung() {

    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-4 mt-4">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="6">
                        <h2>Weinwanderung</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Wie gestaltet sich der Ablauf einer Weinwanderung?</h3>
                            <p>Treffpunkt für die Tour ist direkt an der Alpakawiese in der Alten Wormser Straße 9 in 68623 Lampertheim
                                Rosengarten. Gelegenheit zum Parken besteht direkt an der Wiese.</p>
                            
                            <p>Wenn alle Teilnehmer anwesend sind, gehen wir gemeinsam auf die Wiese und holen die Tiere.
                                Bei der Weinwanderung teilen sich immer zwei Personen ein Tier.
                                Wenn alle Alpakas und Lamas aufgehalftert sind, laufen wir los. Wir wandern auf Wiesen und Feldwegen für ca. 60
                                Minuten. Zeit für Pausen und Bilder ist ebenfalls eingeplant. Wenn wir den Hof wieder erreichen, werden die
                                Tiere auf ihre Wiese entlassen.</p>
                                <p>Das Event lassen wir mit dem Genuss eines Weines (und / oder einem alkoholfreien Kaltgetränk) und einer Brotzeit gemütlich ausklingen.</p>
                            <p>Generell sollte man 120 bis 150 Minuten Zeit einplanen.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                <Col className="ml-auto m-3" md="10">
                    <Card className="card-plain">
                        <img className="img-fluid" alt="..." src={"/img/events/weinwanderung-1.jpg"} />
                    </Card>
                </Col>

            </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was kostet eine Wanderung?</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-pricing card-plain">
                            <CardBody>
                                <h6 className="card-category">Alpaka- & Lamawanderung ab 10 Jahren</h6>
                                <CardTitle tag="h3">€ 80.00 pro Alpaka (2 Personen) inkl. Wein oder Kaltgetränk und Brotzeit</CardTitle>
                                <p className="card-description">
                                    Zwei Personen (über 10 Jahren) teilen sich ein Alpaka oder Lama. 
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-pricing card-plain">
                            <CardBody>
                                <h6 className="card-category">Zusätzliche Begleitpersonen</h6>
                                <CardTitle tag="h3">€ 40.00 pro Person inkl. Wein oder Kaltgetränk und Brotzeit</CardTitle>
                                <p className="card-description">
                                   Zusätzliche Begleitpersonen könnt ihr im Feld "Bemerkungen" bei der Buchung angeben.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-pricing card-plain">
                            <CardBody>
                                <h6 className="card-category">Kinder bis 9 Jahre</h6>
                                <CardTitle tag="h3">kostenlos</CardTitle>
                                <p className="card-description">
                                    Für Kinder bis 9 Jahren ist die Wanderung kostenfrei.
                                    Sie dürfen nur mit einem * einer Erwachsenen zusammen ein Tier führen. Auf eine*n Erwachsene*n darf bei
                                    dieser Wanderung aufgrund der begrenzten Platzanzahl nur ein Kind mitlaufen.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was muss ich noch vor einer Wanderung wissen?</h3>
                            <ul>
                                <li>Der Großteil unserer Wandertiere sind Alpakas. Zusätzlich nehmen wir auf jeder Wanderung wenige Lamas mit, da diese unterwegs die Führung der Gruppe übernehmen. Unser Team entscheidet vor jeder Wanderung, welche Gäste die sanften Riesen führen dürfen.</li>
                                <li>Alpakas und Lamas haben grundsätzlich großen Respekt und teilweise auch Angst vor Hunden, deswegen können
                                    wir leider keine Hunde auf unserem Gelände gestatten.</li>
                                <li>Bring am besten wetterfeste Kleidung mit: Bei Regenwetter eine Regenjacke (keinen Schirm!) und
                                    wasserfeste Schuhe / Gummistiefel.</li>
                                <li> Bei extremen Wetterbedingungen (Sturm, Hagel, Dauerregen, Glatteis, Schneesturm) müssen wir das
                                    Wohlergehen der Tiere immer in den Vordergrund stellen und behalten uns das Recht vor,
                                    Wanderungen auch kurzfristig abzusagen. Selbstverständlich sorgen wir bei einer ausgefallenen
                                    Wanderung für Alternativtermine.</li>
                                    <li>Bitte beachte unsere Regelungen für Stornierungen in unseren <CardLink to="/agb" tag={Link}>AGBs</CardLink>.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Firmenevents, größere Gruppen oder spezielle Wünsche?</h3>
                            <p>Am besten ihr kontaktiert uns per E-Mail (info@alpakas-vom-rosenhof.de). Wir melden uns bei euch, um alles Weitere zu besprechen. </p>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3><strong>Termin buchen</strong></h3>
                        <iframe id="etifr" title="meet_calendar" src="https://www.etermin.net/alpakas-vom-rosenhof?servicegroupid=109655" height="1600px" width="100%" scrolling="no" frameBorder="0"></iframe>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Weinwanderung;
