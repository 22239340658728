import IndexNavbar from "components/Navbars/IndexNavbar.js";

// reactstrap components
import {
    Card, CardBody, CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";


const Geburtstag = (props) => {
    return (<>
        <IndexNavbar transparentUntilScrolled={false} />
        <div className="header-1">

            <div
                className="page-header"
                style={{
                    backgroundImage: `url(/img/events/bday-header.jpg)`
                }}
            >
                <div className="filter" />
                <div className="content-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto" md="12">
                                <h1 className="title"><strong></strong></h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
        <Container className="pt-4 mt-4">

            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h1>Kindergeburtstage</h1>
                        <h5>Wir feiern mit euch und euren Kindern einen Kindergeburtstag, der unvergesslich bleibt.</h5>
                        <p>
                            Die Geburtstage finden auf unserer Wiese im Beisein und natürlich auch in Kontakt mit den Tieren statt.
                            Vom Füttern der Alpakas, über einen kleinen „Parcour“ bis hin zur Schnitzeljagd haben wir ein zwei stündiges Programm zusammengestellt.
                            Essen und Getränke müssten von den Gruppen selbst mitgebracht werden – Sitzgelegenheiten stehen zur
                            Verfügung.</p>


                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h2>Ablauf eines Kindergeburtstages</h2>
                        <p>
                            Wir starten mit dem gemeinsamen Füttern der Tiere. Hier bekommt jedes Kind eine eigene Futterschüssel, so können sich Tier und Kind erst einmal gegenseitig beschnuppern. Dabei stellen wir alle unsere Tiere vor und die Kinder können einige Fakten über Alpakas und Lamas lernen.
                            Im Anschluss halftern wir ein sehr ruhiges Tier auf, das die Kinder durch einen kleinen Parcour aus Pylonen führen dürfen. So erlernen die Kinder den artgerechten Umgang mit unseren Tieren.
                        </p>
                        <p>
                            Für eine kurze Pause können wir unser regen- und windgeschütztes Weidezelt anbieten. Hier können die Kinder mitgebrachte Speisen und Getränke verzehren.
                        </p>

                        <p>
                            Zum Abschluss machen wir mit den unter 10 jährigen Kindern eine Schnitzeljagd. Hier dürfen die Kinder Rätsel lösen, Hinweise suchen, bis sie letztendlich den "Schatz" finden. In der Schatztruhe befindet sich für jedes Kind eine Wollprobe von unseren Tieren als Andenken, sowie ein Alpakasticker und eine Süßigkeit.
                        </p>
                        <p>
                            Mit den Kindern, die mindestens 10 Jahre alt sind, bieten wir alternativ zur Schnitzeljagd auch einen kleinen Spaziergang an. Hier teilen sich immer 2 Kinder ein Tier (aus Sicherheitsgründen).

                            Am Ende bekommt jedes Kind noch eine Urkunde (unabhängig, ob eine Schnitzeljagd oder ein Spaziergang durchgeführt wurde).
                        </p>
                        <p>
                            Insgesamt geht das Programm in etwa zwei Stunden.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h2>Preise</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="3">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">Zwei Stunden Geburtstagsparty</h6>
                            <CardTitle tag="h3">€ 150.00</CardTitle>
                            <p className="card-description">
                                für eine Gruppe von bis zu 8 Kindern und 2 Erwachsenen
                            </p>
                        </CardBody>
                    </Card>
                </Col>             <Col className="ml-auto mr-auto" md="3">
                    <Card className="card-pricing card-plain">
                        <CardBody>
                            <h6 className="card-category">Jedes weitere Kind oder jede weitere Person</h6>
                            <CardTitle tag="h3">€ 15.00</CardTitle>
                            <p className="card-description">
                            </p>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col className="ml-auto m-3" md="5">
                    <Card className="card-plain">
                        <img className="img-fluid" alt="..." src={"/img/events/bday1.jpg"} />
                    </Card>
                </Col>
                
                <Col className="ml-auto m-3" md="5">
                    <Card className="card-plain">
                        <img className="img-fluid" alt="..." src={"/img/events/bday2.jpg"} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3>Was müsst ihr noch wissen?</h3>
                        <ul>
                            <li>auf unserem Gelände sind keine Hunde erlaubt</li>
                            <li>wetterfeste Kleidung, bei Hitze einen Sonnenschutz mitbringen</li>
                            <li>ggf. Kühlmöglichkeiten / Kühlakkus für die mitgebrachten Lebensmittel und Getränke mitbringen</li>
                            <li>wir bitten euch, verursachten Müll wieder mitzunehmen und zu Hause zu entsorgen</li>
                            <li>geparkt werden kann direkt an der Alpakakoppel</li>

                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3>Wie könnt ihr einen Geburtstag buchen?</h3>
                        <p>Am besten, ihr schreibt uns eine E-Mail an info@alpakas-vom-rosenhof.de mit eurer Anfrage. Wir melden uns dann, um alles Weitere zu besprechen.</p>
                    </div>
                </Col>
            </Row>

        </Container>
    </>);
}

export default Geburtstag;

