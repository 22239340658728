const events = [

    {
        id: 0,
        name: "Wanderung",
        title: "Weinwanderung",
        icon: "/img/events/hiking_black_24dp.svg",
        description: "Wir wandern durch die schönen Felder in Lampertheim Rosengarten und verkosten Weine aus der Region.",
        image_url: "/img/events/cards/weinwanderung.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/weinwanderung",
        link_text: "Details und Termine"
    },
    {
        id: 1,
        name: "Wanderung",
        title: "Alpakerol Spritz Wanderung",
        icon: "/img/events/hiking_black_24dp.svg",
        description: "Erkunde mit unseren liebenswerten Alpakas die Natur und genieße einen spritzigen Aperol im Beisein der Tiere.",
        image_url: "/img/events/cards/aperol.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/aperolwanderung",
        link_text: "Details und Termine"
    },
    
    {
        id: 2,
        name: "Alpaka x Gin",
        title: "Alpaka meets Gin",
        icon: "/img/events/yoga_black_24dp.svg",
        description: "Lasst euch in die Welt der Alpakas und des Gins entführen.",
        image_url: "/img/events/cards/gin.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/gin",
        link_text: "Details und Termine"
    },
    {
        id: 3,
        name: "Wanderung",
        title: "Ein Spaziergang mit Alpakas",
        icon: "/img/events/hiking_black_24dp.svg",
        description: "Lernt die Alpakas und Lamas bei einem 75 minütigen Spaziergang kennen.",
        image_url: "/img/events/cards/wanderung.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/wanderung",
        link_text: "Details und Termine"
    },
    {
        id: 4,
        name: "Kindergeburtstag",
        title: "Kindergeburtstag auf der Alpakafarm",
        icon: "/img/events/yoga_black_24dp.svg",
        description: "Wir feiern mit euch und euren Kindern einen Kindergeburtstag, der unvergesslich bleibt.",
        image_url: "/img/events/cards/geburtstag.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/geburtstag",
        link_text: "Details"
    },
    {
        id: 5,
        name: "Meet & Greet",
        title: "Trefft die Alpakas in ihrem Zuhause",
        icon: "/img/events/waving_hand_black_24dp.svg",
        description: "Beim Füttern der Tiere kommt ihr den Alpakas nahe und lernt einiges über die Tiere sowie die Haltung.",
        image_url: "/img/events/cards/meet.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/meet",
        link_text: "Details und Termine"
    },
    {
        id: 6,
        name: "Yoga",
        title: "Yoga auf der Alpakawiese",
        icon: "/img/events/yoga_black_24dp.svg",
        description: "Inmitten der grasenden Alpakas entspannt ihr bei einer Yogaeinheit.",
        image_url: "/img/events/cards/yoga.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/yoga",
        link_text: "Details und Termine"
    },
    {
        id: 7,
        name: "Wanderung",
        title: "Glühweinwanderung",
        icon: "/img/events/hiking_black_24dp.svg",
        description: "Gut aufgewärmt mit einem Glühwein trotzen wir der kalten Jahreszeit auf einer Alpakawanderung.",
        image_url: "/img/events/cards/gluehwein.jpg",
        link_icon: "fa fa-info mr-1",
        link: "events/gluhwein",
        link_text: "Details und Termine"
    }
]
export default events;