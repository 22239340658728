import IndexNavbar from "components/Navbars/IndexNavbar.js";

// reactstrap components
import {
    Card,
    CardBody,
    CardTitle,
    CardLink,
    Container,
    Row,
    Col,
} from "reactstrap";
import { Link } from "react-router-dom";

const Meet = (props) => {
    return (<>
        <IndexNavbar transparentUntilScrolled={false} />
        <div className="header-1">

            <div
                className="page-header"
                style={{
                    backgroundImage: `url(/img/events/meet-header.jpg)`
                }}
            >
                <div className="filter" />
                <div className="content-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto" md="12">
                                <h2 className="title"><strong>Meet and Greet</strong></h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
        <Container className="pt-4 mt-4">


            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <div className="article-content">
                        <h3> <strong>Was kann ich mir unter einem Meet and Greet mit den Alpakas vorstellen?</strong></h3>
                        <p>
                            Bei einem Meet and Greet mit den Alpakas nehmen wir euch 45 Minuten auf die Wiese, auf der die Tiere
                            leben. Ihr erfahrt etwas über die Haltung und die Tierart Alpaka. Ihr dürft die Tiere füttern und kommt
                            der gesamten Herde sehr nah.
                        </p>
                        <p>
                            Dieses Event eignet sich sehr für kleine Kinder unter 6 Jahren, die noch keine lange Wanderung
                            „durchhalten“ oder für Personen, die nicht gut zu Fuß sind, aber trotzdem in den Kontakt mit diesen
                            wunderbaren Tieren kommen wollen. Dieses Event ist demnach auch für Rollstuhlfahrer, Menschen mit
                            Gehbehinderung oder Mama's mit Kinderwägen geeignet.
                        </p>
                        <h3><strong>Was kostet das Meet and Greet?</strong></h3>
                        <Row>

                            <Col className="ml-auto m-3" md="5">
                                <Card className="card-plain">
                                    <img className="img-fluid" alt="..." src={"/img/events/meet-3.jpg"} />
                                </Card>
                            </Col>
                            <Col className="ml-auto m-3" md="5">
                                <Card className="card-plain">
                                    <img className="img-fluid" alt="..." src={"/img/events/meet-2.jpg"} />
                                </Card>
                            </Col>

                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="3">
                                <Card className="card-pricing card-plain">
                                    <CardBody>
                                        <h6 className="card-category">Kinder zwischen 0 und 3 Jahren</h6>
                                        <CardTitle tag="h3">kostenfrei</CardTitle>
                                        <p className="card-description">
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <Card className="card-pricing card-plain">
                                    <CardBody>
                                        <h6 className="card-category">Kind zwischen 4 und 11 Jahren</h6>
                                        <CardTitle tag="h3">€ 8.00</CardTitle>
                                        <p className="card-description">
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <Card className="card-pricing card-plain">
                                    <CardBody>
                                        <h6 className="card-category">Kind ab 12 Jahren oder pro Erwachsene*r</h6>
                                        <CardTitle tag="h3">€ 12.00</CardTitle>
                                        <p className="card-description">
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <h3><strong>Was muss ich noch vor einem Meet and Greet wissen?</strong></h3>
                        <ul>
                            <li>Alpakas haben grundsätzlich großen Respekt und teilweise auch Angst vor Hunden, deswegen können wir leider keine Hunde auf unserem Gelände gestatten.</li>
                            <li>Bring am besten wetterfeste Kleidung mit: Bei Regenwetter eine Regenjacke (keinen Schirm!) und wasserfeste Schuhe / Gummistiefel, im Sommer ggf. eine Kopfbedeckung und ausreichend Sonnencreme, mit.</li>
                            <li>Bei extremen Wetterbedingungen (Sturm, Hagel, Dauerregen, Glatteis, extremer Hitze) müssen wir das Wohlergehen der Tiere immer in den Vordergrund stellen und behalten uns das Recht vor, das Meet and Greet auch kurzfristig abzusagen. Selbstverständlich sorgen wir bei einem ausgefallenen Meet and Greet für Alternativtermine.</li>
                            <li>Bitte beachte unsere Regelungen für Stornierungen in unseren <CardLink to="/agb" tag={Link}>AGBs</CardLink>.</li>
                        </ul>
                        <h3><strong>Termin buchen</strong></h3>

                        <Row>

                            <Col className="ml-auto m-3" md="10">
                                <Card className="card-plain">
                                    <img className="img-fluid" alt="..." src={"/img/events/meet-1.jpg"} />
                                </Card>
                            </Col>
                            

                        </Row>
                        <iframe id="etifr" title="meet_calendar" src="https://www.eTermin.net/alpakas-vom-rosenhof?servicegroupid=100344" height="1600px" width="100%" scrolling="no" frameBorder="0"></iframe>

                    </div>
                </Col>
            </Row>
        </Container>
    </>);
}

export default Meet;