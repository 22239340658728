import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

const IndexNavbar = (props) => {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const updateNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  useEffect(() => {
    if (!props.transparentUntilScrolled) {
      setNavbarColor("");
    } else {
      window.addEventListener("scroll", updateNavbarColor);
    }
    return function cleanup() {
      if (props.transparentUntilScrolled) {
        window.removeEventListener("scroll", updateNavbarColor);
      }
    };
  }, [props.transparentUntilScrolled]);

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand data-placement="bottom" to="/" target="_self" tag={Link}>
            Alpakas vom Rosenhof
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/events" tag={Link}>
                Wanderungen & Events
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gutschein" tag={Link}>
                Gutscheine
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/fotoshooting" tag={Link}>
                Fotoshooting
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/animals" tag={Link}>
                Unsere Tiere
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/patenschaft" tag={Link}>
                Patenschaft
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/anfahrt" tag={Link}>
                Anfahrt
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact" tag={Link}>
                Kontakt
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/alpakas_vom_rosenhof"
                target="_blank"
                title="Folge uns auf Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default IndexNavbar;
