
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, NavLink, Container } from "reactstrap";

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <NavLink to="/impressum" tag={Link}>
                  Impressum
                </NavLink>
              </li>
              <li>
                <NavLink to="/agb" tag={Link}>
                  Allgemeine Geschäftsbedingungen
                </NavLink>
              </li>
              <li>
                <NavLink to="/datenschutz" tag={Link}>
                  Datenschutzerklärung
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            {/*
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by
              <Button
                className="mr-1"
                color="link"
                href="https://www.linkedin.com/in/felix-wustrack-a51a7716b/"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-linkedin-square" />
                Felix Wustrack
              </Button>
            </span>
             */}
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
