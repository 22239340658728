import React from "react";
import {
    Container, Row, Col, Card,
    CardBody,
    CardTitle, Button
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Fotoshooting() {
    document.documentElement.classList.remove("nav-open");
    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-5">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="6">
                        <h2>Ein Fotoshooting der besonderen Art</h2>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col className="ml-auto" md="12">
                        <Card className="card-plain">
                            <CardBody>
                                <CardTitle tag="h3">
                                    Einzigartiges Fotoshooting mit den flauschigen Alpakas
                                </CardTitle>

                                <p className="card-description">
                                    Schnappe deine besten Freunde, deine*n Partner*in oder deine Familie und sichere dir einzigartige Fotos
                                    mit unseren Wollnasen.
                                </p>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="mr-auto" md="5">
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src="/img/fotoshoot/fotoshooting-5.jpg" />
                        </Card>
                    </Col>
                    <Col className="mr-auto" md="5">
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src="/img/fotoshoot/fotoshooting-4.jpg" />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Ablauf</h3>
                        <p>Zunächst bekommen alle Teilnehmer*innen eine Einführung in das Handling eines Alpakas. Ihr bekommt
                            von uns erklärt, worauf man beim Führen eines Alpakas achten muss, ihr könnst erste Kontakte zu den
                            Tieren knüpfen, sodass beim Fotoshooting das Eis zwischen euch bereits gebrochen ist.</p>
                        <p>Anschließend laufen wir zu einem oder mehreren Fotospots, die zu Fuß von unserem Hof in
                            maximal 15 Minuten zu erreichen sind. Hier kann das Fotoshooting starten.
                            Das Fotoshooting dauert in etwa 90 Minuten und findet ausschließlich bei uns auf und in der Nähe des
                            Rosenhofs statt.</p>
                        <p>Der Fotograf sowie die Tiere werden von uns natürlich gestellt.</p>
                        <p>Am Ende des Fotoshootings schaut ihr euch zusammen mit dem Fotografen die Bilder an, ihr dürft euch
                            insgesamt 5 Fotos aussuchen, die wir für euch bearbeiten. Diese bekommt ihr digital zur Verfügung
                            gestellt.</p>
                    </Col>

                </Row>
                <Row className="align-items-center">

                    <Col className="mr-auto" md="5">
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src="/img/fotoshoot/fotoshooting-3.jpg" />
                        </Card>
                    </Col>
                    <Col className="ml-auto" md="5">
                        <Card className="card-plain">
                            <CardBody>
                                <h6 className="card-category">Preise</h6>
                                <CardTitle tag="h3">
                                    für Einzelpersonen, Paare, Freunde & Familien
                                </CardTitle>

                                <p className="card-description">
                                    Ein 90 minütiges Fotoshooting für bis zu 4 Personen oder eine Familie kostet 230 €.
                                    Für jede weitere angefallene halbe Stunde berechnen wir 50 € extra.
                                </p>

                            </CardBody>
                        </Card>
                    </Col>


                </Row>
                <Row className="section text-center" >
                    <Col className="ml-auto mr-auto" md="8">
                        <h2 className="title">Buchen</h2>
                        <h5>
                            Um ein Fotoshooting zu buchen, schreibt uns eine E-Mail an info@alpakas-vom-rosenhof.de oder nutzt unser Kontaktformular.
                        </h5>
                        <br />
                        <Button
                            className="btn-fill btn-round"
                            to="/contact" tag={Link}
                        >
                            Zum Kontaktformular
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="mr-auto" md="10">
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src="/img/fotoshoot/fotoshooting-1.jpg" />
                        </Card>
                    </Col>

                </Row>
            </Container>
        </>
    );
}
export default Fotoshooting;