import React from "react";
import {
    Card,
    CardFooter,
    CardBody,
    CardTitle, 
    Button,
    Row, Col
} from "reactstrap";


const EventCard = (props) => {


    const { name, image_url, title, description, link, link_icon, link_text, warning } = props.event;
    return (
        <>
            <div className="space-top" />

            <Row  className="align-items-center">
                <Col className="ml-auto eventcard" md="5">
                    <a className="stretched-link text-decoration-none" href={link}>
                        <Card className="card-plain">
                            <img className="img-fluid" alt="..." src={image_url} />
                        </Card>
                    </a>
                </Col>
                <Col className="ml-auto" md="5" style={{ innerHeight: "420px" }}>
                    <Card className="card-plain">
                        <CardBody>
                            <h6 className="card-category">{name}</h6>

                            <CardTitle tag="h3">
                                {title}
                            </CardTitle>

                            <p className="card-description">
                                {description}
                            </p>
                            <h3 className="text-danger"><strong>{warning}</strong></h3>
                            <CardFooter>
                                <Button
                                    className="mr-1"
                                    color="link"
                                    href={link}
                                >
                                    <i className={link_icon} />
                                    {link_text}
                                </Button>
                            </CardFooter>

                        </CardBody>
                    </Card>

                </Col>
            </Row>

        </>
    );
};
export default EventCard;