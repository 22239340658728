/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "contactFormApi",
            "endpoint": "https://p335bki13m.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "formtable-master",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
