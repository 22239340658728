import YoutubeEmbed from "components/YoutubeEmbed";
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
function SectionVideo() {

    return (
        <Container>
            <Row className="justify-content-center">
                <Col className="mx-auto">
                    <YoutubeEmbed embedId="vv9auXpVQeA" />
                </Col>
            </Row>
        </Container>

    );
}
// 
export default SectionVideo;
