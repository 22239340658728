import React from "react";
import { Container, Row } from "reactstrap";
// core components
import AlpakaList from "../components/animals/AlpakaList";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import alpakas from "../components/animals/alpakas";
function Animals() {
  document.documentElement.classList.remove("nav-open");
  return (
    <>
      <IndexNavbar transparentUntilScrolled={false} />
      <Container className="pt-5">
        <Container className="mt-4">
          <Row>
            <h1>Unsere Tiere</h1>
          </Row>
        </Container>
        <Container>
          <Row>
            <AlpakaList alpakas={alpakas} />
          </Row>
        </Container>
    
      </Container>
    </>
  );
}
export default Animals;
