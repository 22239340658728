import "./AlpakaList.css";
import React from "react";
import AlpakaCard from "./AlpakaCard";
import { Container } from "reactstrap";
const AlpakaList = (props) => {
  const images = props.alpakas.map((alpaka) => {
    return <AlpakaCard key={alpaka.id} image={alpaka} />;
  });
  return <Container className="image-list">{images}</Container>;
};

export default AlpakaList;
