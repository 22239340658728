import React from "react";
import { connect } from "react-redux";

// core components
import ContactForm from "components/ContactForm";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import { sendContactForm } from "../actions";

const Contact = (props) => {
  const onSubmit = (formValues) => {
    props.sendContactForm(formValues);
  };
  document.documentElement.classList.remove("nav-open");
  return (
    <>
      <IndexNavbar transparentUntilScrolled={false} />
      <div className="main">
        <div className="section text">
          <ContactForm onSubmit={onSubmit} />
        </div>
      </div>
    </>
  );
};

export default connect(null, { sendContactForm })(Contact);
