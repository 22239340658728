import { SEND_CONTACT_FORM } from "./types";
import { API } from "aws-amplify";

export const sendContactForm = (formValues) => async (dispatch) => {
  const data = {
    body: {
      name: formValues.name,
      email: formValues.email,
      message: formValues.message,
    },
  };
  const response = await API.post("contactFormApi", "/contact", data);
  dispatch({ type: SEND_CONTACT_FORM, payload: response });
};
