const alpakas = [
  {
    id: 1,
    name: "Leo",
    image_url: "/img/alpakas/leo.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Nicole Deuser",
    pate_25: true,
    pate_25_name: "Nicole Deuser",
    teaser: "Hi, mein Name ist Leo!",
    description:
      "Ich wohne in der Männer – WG und bin (zumindest zur Zeit) noch der Chef. Ich bin der Stärkste von den Jungs und in der Herde ziemlich dominant. Wenn ich mit euch wandern gehe, bin ich aber ein kleiner Angsthase und verstecke mich gerne hinter meinem Kumpel George.<br> <br> Meine Menschen haben mich ausgewählt, weil sie meiner tiefschwarzen Schönheit nicht widerstehen konnten. Sie nennen mich manchmal auch Black Beauty – und das obwohl mir Pferde ganz schön suspekt sind. Die spucke ich gerne an, wenn sie mir zu nahe kommen. ;-)",
  },
  {
    id: 2,
    name: "George",
    image_url: "/img/alpakas/georg.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Sarah Pinger",
    teaser: "Hola Chicas! Ich bin's George!",
    description:
      "Ich habe Flecken wie eine Kuh und mein Vließ ist gaaaaanz weich, denn ich bin ein Huacaya – Alpaka. Ich bin sehr mutig und habe die Aufpasser – Funktion in der Herde. Ich schlafe gerne draußen, um als Erster zu sehen, wenn uns mal wieder nachts eine Katze auf der Koppel besucht. Ansonsten werde ich von meinen Menschen gerne als „kleiner Dickkopf“ bezeichnet.",
  },
  {
    id: 3,
    name: "Dyonis",
    image_url: "/img/alpakas/didi.jpg",
    sponsorship: false,
    pate: true,
    teaser: "Hey ho, mein Name ist Dyonis!",
    description:
      "Von meinen menschlichen Freunden werde ich auch gerne „Didi“ genannt. Ich bin der Coole unter den Jungs. Warum?! Meine Besitzer sagen, ich sei der Bravste und Zutraulichste. Ich lasse mir ziemlich viel gefallen, auch Streicheln finde ich eigentlich ganz okay. Außerdem bin ich ein Suri – Alpaka. Das bedeutet, meine Wolle sieht etwas anders aus. Cool eben!",
  },
  {
    id: 4,
    name: "Adam",
    image_url: "/img/alpakas/adam.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Patricia Hornberger",
    teaser: "Servus, ich bin's, der Adam.",
    description:
      "Ich bin auch ein Suri – Alpaka. Man erkennt mich daran, dass ich etwas dunkler als Dyonis bin UND ich bin definitiv der Verfressenste von allen. <br><br>Wenn ich dann auch noch so verhungert aus der Wäsche schaue, können die Menschen mir nicht widerstehen. Auf Wanderungen geb' ich übrigens generell Gas!",
  },
  {
    id: 5,
    name: "Carlos",
    image_url: "/img/alpakas/carlos.jpg",
    sponsorship: false,
    pate: true,
    teaser: "Hi, ich bin Carlos!",
    description:
      "Ich bin ein Huacaya – Alpaka und genau wie George sehr flauschig. Im Winter bin ich ein richtiges „Puschel“. Mir wird nachgesagt, ich sei eine „Diva“ . Denn zum einen habe ich die längsten und schönsten Wimpern, zum anderen werde ich zur Diva, wenn man mich anfassen möchte. Das kann ich nämlich gar nicht leiden. Wenn man mich anschaut, bewundert und anschmachtet gefällt mir das deutlich besser.",
  },
  {
    id: 6,
    name: "Paul",
    image_url: "/img/alpakas/paul.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Miriam Vierling",
    pate_25: true,
    pate_25_name: "Miriam Vierling",
    teaser: "Hey, ich bin der süße Paul!",
    description:
      "Und nein, ich bin kein Kuscheltier, ich bin echt! Ich bin klein, aber oho! Denn ich kann ganz schön frech sein. Die anderen Alpakas bespringen und anpöbeln ist mein Hobby. Zur allgemeinen Unterhaltung trägt meine Performance auf den Wanderungen definitiv bei.",
  },
  {
    id: 7,
    name: "Muck",
    image_url: "/img/alpakas/muck.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Michael Geist",
    teaser: "Hallöchen, mein Name ist Muck.",
    description:
      "Ich bin ein rotbraunes Alpaka und bin der Größte und Schwerste von den Jungs. Ich bringe 90 Kilogramm auf die Waage! Meine Besitzer haben schon gemunkelt, ob in meinen Genen ein Lama verwurzelt ist, weil ich so viel wiege wie ein kleines Lamas. Frechheit sowas.",
  },
  {
    id: 8,
    name: "Benno",
    image_url: "/img/alpakas/benno.jpg",
    sponsorship: true,
    pate: false,
    pate_25: true,
    pate_25_name: "Selina Bolliger",
    teaser: "Hallo! Ich bin Benno.",
    description:
      "Ich bin der Jungspund in der Männerrunde. Meine Mama ist übrigens die Babbsy. Von ihr habe ich meine schöne, braune Vließfarbe geerbt. Ich werde später mal ein toller Deckhengst.",
  },
  {
    id: 9,
    name: "Oskar",
    image_url: "/img/alpakas/oskar.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Heidi und Martin Stoewer",
    teaser: "Servus, ich heiße Oskar!",
    description:
      "- Willst du Action, Spaß und Freud, frag, ob dich Oskar auf den Wanderungen betreut. Mich ausführen ist ein Abenteuer, denn in mir lodert ein Alpaka-Feuer. -",
  },
  {
    id: 10,
    name: "Jingle Bells",
    image_url: "/img/alpakas/jingle.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Birgit Wittermann",
    teaser: "Hallo zusammen! Mein Name ist Jingle Bells.",
    description:
      "Ja, ihr habt richtig gehört. Ich bin ein gepunktetes Huacaya – Alpaka, auch wenn man die Punkte oft nicht erkennt und es so aussieht, als wäre ich einfach nur etwas dreckig. Meine Menschen sagen oft, ich bin ein richtiger Goldschatz. Deshalb haben sie mich auch dazu auserkoren, viele Babys zu produzieren.",
  },
  {
    id: 11,
    name: "Bo",
    image_url: "/img/alpakas/bo.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Johanna Jakobi",
    teaser: "Seid gegrüßt – Ich bin's, der Bo.",
    description:
      "Ich gehöre zur Lametten – Truppe. Ich bin ein Wooly – Lama und bin weiß – braun gescheckt. Man erkennt mich an meinen weißen Ohren. Ich bin eher etwas zurückhaltend und schaue mir die Menschen lieber mit ein paar Meter Abstand an.",
  },
  {
    id: 12,
    name: "Vly",
    image_url: "/img/alpakas/Vly.jpg",
    sponsorship: true,
    pate: false,
    teaser: "Hello, ich bin der Vly.",
    description:
      "Ich bin auch ein Wooly – Lama. Ich bin das einzige ganz braune Lama auf dem Rosenhof. Meine Besitzer finden, ich sehe dem Dyonis sehr ähnlich. Charakterlich bin ich aber ein kleines Sensibelchen und nicht so ruhig und gelassen wie der Dyonis. Ich bin aber dennoch ein sehr artiges Lama und gebe mir sehr viel Mühe.",
  },
  {
    id: 13,
    name: "Fjodor",
    image_url: "/img/alpakas/fjodor.jpg",
    sponsorship: true,
    pate: false,
    teaser: "Huhu, mein Name ist Fjodor.",
    description:
      "Ich bin auch ein Wooly – Lama und genauso gescheckt wie meine Kumpels Bo und Yoko. Man kann mich aber an meinen ganz dunklen Ohren erkennen. Wenn's irgendwo was zu essen gibt, kann man mich nicht mehr halten – denn ich bin super verfressen und werde zum Schatten von jedem, der das Futter transportiert.",
  },
  {
    id: 14,
    name: "Franzl",
    image_url: "/img/alpakas/franzl.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Daniela Gispert",
    pate_25: true,
    pate_25_name: "Daniela Gispert",
    teaser: "Hi! Ich heiße Franzl.",
    description:
      "Ich bin das Weiße unter den Lamas und ein Classic-Lama. Ich bin der Älteste und Schwerste der Lama – Truppe. Ich bin ziemlich cool mit allem – ich geh mit den Menschen mit, wohin sie wollen.",
  },
  {
    id: 15,
    name: "Yoko",
    image_url: "/img/alpakas/yoko.jpg",
    sponsorship: false,
    pate: true,
    teaser: "Ey yo, ich bin Yoko!",
    description:
      "Ich bin auch ein Wooly-Lama und weiß - braun gescheckt. Mich erkennt man daran, dass ich ein weißes und ein braunes Ohr habe – cool was? Ich bin auch eher etwas zurückhaltend und verstecke mich gerne hinter Fjodor und Franzl.",
  },
  {
    id: 16,
    name: "Inge",
    image_url: "/img/alpakas/inge2.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Moin, moin! Ich heiße Inge.",
    description:
      "Ich bin ein weißes Huacaya – Alpaka. Ein kleiner „Unfall“ (Leo das Schlitzohr hat sich zu mir auf die Koppel geschlichen) hat dazu geführt, dass ich Mama geworden bin. Ich bin sehr dankbar für meine Tochter, die Gundel. Von den Wanderungen bleibe ich aber immer zu Hause, denn ich muss ja auf die Gundel aufpassen.",
  },
  {
    id: 17,
    name: "Gundel",
    image_url: "/img/alpakas/gundel.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Hey, na? Ich bin die Gundel.",
    description:
      "Ich bin die Tochter von Leo und Inge und finde, ich sehe meinem Papa ähnlich. Ich bin zwar braun, aber wenn ihr mich genau anschaut, seht ihr, dass meine Beine und mein Kopf ganz schwarz sind. Wie bei Papa. Ich genieße derzeit mein Leben auf der Wiese mit den anderen Damen und tolle mit meinen Freunden ganz viel herum.",
  },
  {
    id: 18,
    name: "Penny",
    image_url: "/img/alpakas/penny.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Hola, ¿qué tal, amigo?",
    description:
      "Ich bin die wunderschöne Penny. Ich wurde in Südamerika geboren und lebe jetzt auf dem Rosenhof. Wenn meine Besitzer zu langsam beim Füttern sind, werde ich manchmal zickig, sonst bin ich aber zuckersüß und das weiß ich auch ganz genau. Meine Tochter ist die Jule, die war ein absolutes Wunschkind und macht mich und meine Besitzer ganz stolz.",
  },
  {
    id: 19,
    name: "Jule",
    image_url: "/img/alpakas/jule.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Guten Tag! Ich bin die Jule.",
    description:
      "Ich habe die Gemütlichkeit von meiner Mama geerbt. Ich bin sowas von cool – rumrennen mit den anderen Kindern ist mir ehrlich gesagt zu anstrengend. Ich fresse lieber Gras und lasse mir die Sonne auf den Bauch scheinen. Ja ich weiß – ich sehe unglaublich süß aus.",
  },
  {
    id: 20,
    name: "Babbsy",
    image_url: "/img/alpakas/babbsy.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Seid gegrüßt, ihr Menschen. Ich bin die Babbsy.",
    description:
      "Ich bin die Chefin meiner Mädelstruppe und des Alpaka - Kindergartens. Ich kann eine ziemlich große Zicke sein – gerade dann – wenn ich die anderen Damen und Kinder erziehen muss. Aber eine muss den Job ja machen. Ehrlich gesagt fühle ich mich dabei auch am wohlsten. Mit den Menschen zusammen spazieren gehen ist nicht so mein Ding. Daher darf ich immer daheim bleiben.",
  },
  {
    id: 21,
    name: "Puri",
    image_url: "/img/alpakas/puri.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Seid gegrüßt, ihr Menschen. Ich bin die Puri.",
    description:
      "Eigentlich lautet mein Name Singapur, aber meine Besitzer sind der Meinung, Puri klingt irgendwie süßer. Da ich sehr hübsch und knuffig bin, passt das wohl besser zu mir. Wenn ich ganz groß bin, will ich auch eine tolle Mama werden und viele, viele Punkte weitervererben. Die Alpaka-Männer vom Rosenhof gefallen mir auf jeden Fall ziemlich gut. Ich hab' schon den ein oder anderen Blick über den Zaun geworfen.",
  },
  {
    id: 22,
    name: "Nella",
    image_url: "/img/alpakas/nella.jpg",
    sponsorship: false,
    pate: false,
    teaser: "Hey ihr! Mein Name ist Nella.",
    description:
      "Also eigentlich Citronella. Ich bin die beste Freundin von der Puri. Wir kennen uns schon seit unserer Geburt. Ich habe ein kleines Handycap: Seit meiner Geburt stimmt da was mit meiner Hüfte nicht. Aber das bereitet mir keine Schmerzen und ich kann wie die anderen Alpakas über die Koppel rennen – Gott sei Dank. Aber auf Wanderungen wollen mich meine Besitzer nie mitnehmen, das wäre dann vielleicht doch zu viel Belastung, sagen sie. Und ich muss sagen, dass das Lotter-Leben auf der Koppel spitzenmäßig für mich ist!",
  },
  {
    id: 23,
    name: "Zeus",
    image_url: "/img/alpakas/Zeus.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Ute Büchler und Martin Ruckwitz",
    teaser: "Grüß Gott! Also... MICH!",
    description: "Ich bin Zeus und springe jedem wegen meiner tollen Vließfarbe ins Auge. Grau und Flecken – das ist wirklich eine göttliche Farbe! Ich weiß, dass ich super aussehe. 😉 <br> <br>"
  },
  {
    id: 24,
    name: "Juri",
    image_url: "/img/alpakas/Juri.jpg",
    sponsorship: false,
    pate: true,
    pate_name: "Marlo Burkhardt",
    teaser: "Juhu, ich bin Juri!",
    description: "Ich bin ein ganz gemütlicher kleiner, nein, großer Kerl!<br> <br>  Geboren mit 10 kg hat sich mein Gewicht in den ersten 2 Lebensmonaten fast verdreifacht. Ich bin halt ein ganz schöner Wonneproppen. So gut, wie sich meine Mama Penny, meine Schwester Jule und meine beste Patentante Nella um mich sorgen, könnte es mir gar nicht besser gehen. Ich darf später, wenn ich groß bin, mal die Wandergruppe ergänzen. Das wird ein Spaß!"

  },
  {
    id: 25,
    name: "Viktor",
    image_url: "/img/alpakas/viktor.jpg",
    sponsorship: true,
    pate: false,
    teaser: "Hallo ihr Lieben!",
    description: "Ich bin der süße, zurückhaltende Victor. Man findet mich eigentlich immer im Stall, wo ich gerne alleine am Heu stehe und esse. Das ist meine Lieblingsbeschäftigung. Beim Wandern bin ich eher schüchtern und schaue mir die Gäste lieber mit etwas Abstand von hinten an. Bist du auch introvertiert? Dann wärst du ein toller Wanderbegleiter für mich."
  },
  {
    id: 26,
    name: "Amor",
    image_url: "/img/alpakas/amor.jpg",
    sponsorship: false,
    pate: false,
    pate_25: true,
    pate_25_name: "Lucas Vierling",
    teaser: "Amore mio!",
    description: "Ich bin Amor, der Liebesgott. Ich bin ein bisschen verrückt vor lauter Liebe. Ich verehre unsere Alpakadamen, aber da ich zu denen nie darf (denn Mädels und Jungs werden bei den Alpakas vom Rosenhof aufgrund von ungewollten Schwangerschaften strikt getrennt) habe ich mich vorübergehend in Franzl verliebt. Auf Wanderungen bin ich sein Schatten (und sonst auch)."
  },
  {
    id: 27,
    name: "Apollon",
    image_url: "/img/alpakas/apollon.jpg",
    sponsorship: false,
    pate: false,
    pate_25: true,
    pate_25_name: "Melanie Deutsch",
    teaser: "Grüß Gott!",
    description: "Mein Name ist Apollon. Ich bin ein kleiner Draufgänger in der Herde, obwohl ich zu den ältesten Alpakas vom Rosenhof gehöre. Ich bin ein gemütliches Wandertier und darf aufgrund meines Alters aber auch oft zu Hause bleiben. Da soll ich mich eigentlich ausruhen, stattdessen sorge ich aber gerne mal für etwas Action und gehe den anderen Tieren (vor allem den Lamas auf die Nerven)."
  },
  {
    id: 28,
    name: "Dios",
    image_url: "/img/alpakas/dios.jpg",
    sponsorship: true,
    pate: false,
    teaser: "Hey!",
    description: "Ich bin Dios mit der schönsten Frisur, welche mein Gesicht umschmeichelt. Ich bin ein sehr erfahrenes und ruhiges Wandertier. Gerne begleite ich dich mit aller Ruhe auf einem Spaziergang."
  },
  {
    id: 29,
    name: "David",
    image_url: "/img/alpakas/david.jpg",
    sponsorship: true,
    pate: false,
    teaser: "Hallöchen!",
    description: "Ich bin David, der Bruder von Dyonis. Optisch kann man mich kaum von Dyonis unterscheiden. Da ich aber erst vier Jahre nach meinem Bruder den Weg zum Rosenhof gefunden habe, bin ich noch etwas ängstlich."
  },
  {
    id: 30,
    name: "Bilbo",
    image_url: "/img/alpakas/bilbo.jpg",
    sponsorship: true,
    pate: false,
    pate_25: true,
    pate_25_name: "Daniel und Daniela",
    teaser: "Guten Tag!",
    description: "Mein Name ist Bilbo und ich bin ein Meisterdieb. Doch ohne meinen Kumpel Juri gehe ich ungern auf Wanderschaft. Wir Hobbits erleben Abenteuer gerne gemeinsam. "
  }
];
export default alpakas;
